import React, { useEffect, useState } from "react";
import LightBox from "./lightbox";
import { Carousel } from "../Carousel";
import useWindowSize from "../../lib/useWindowSize";
import Placeholder from "../../assets/placeholder.svg";
import { GatsbySanityImage } from "../GatsbySanityImage";

const GalleryComponent = ({
  headerImages: headerImages_,
  styles,
  carouselClassName,
  carouselProps,
}) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [numberOfSlides, setNumberOfSlides] = useState(3);
  // const [cellSpacing, setCellSpacing] = useState(10);
  const size = useWindowSize();
  const { width } = size;
  const isMobileOnly = width <= 576;
  useEffect(() => {
    const isTablet = width > 576 && width < 780;
    const isTabletL = width > 780 && width < 992;
    const isSreenSM = width > 992 && width < 1024;
    const isSreenLG = width > 1024 && width < 1440;
    const screenXL = width > 1440 && width < 1600;
    const screenXXL = width > 1600 && width < 1700;
    const screenXXXL = width > 1700;

    const slides = () => {
      if (isMobileOnly) return 1;
      if (isTablet) return 1;
      if (isTabletL) return 1;
      if (isSreenSM) return 1;
      if (isSreenLG) return 1;
      if (screenXL) return 1;
      if (screenXXL) return 1;
      if (screenXXXL) return 1;
      return 1;
    };
    setNumberOfSlides(slides);
  }, [size]);

  const handleOpen = (index) => {
    setSelectedImage(index);
    setShowLightbox(true);
  };

  const handleClose = () => {
    setShowLightbox(false);
    setSelectedImage(null);
  };

  const headerImages = { ...headerImages_ };

  return (
    <>
      <Carousel
        slidesToShow={numberOfSlides}
        style={styles}
        centerMode={false}
        className={carouselClassName || ""}
        {...carouselProps}
      >
        {headerImages?.images?.length
          ? headerImages?.images.map((image, index) => {
              return (
                <div key={image.alt} className="sanity-image">
                  {image?.asset && (
                    <GatsbySanityImage
                      assetId={image?.asset?._id}
                      gatsbyImage={image?.asset?.gatsbyImage}
                      alt={image.alt}
                      onClick={() => handleOpen(index)}
                    />
                  )}
                </div>
              );
            })
          : [1, 2, 3].map((item) => <Placeholder />)}
      </Carousel>

      {!isMobileOnly && showLightbox && selectedImage !== null ? (
        <LightBox
          showLightbox={showLightbox}
          images={headerImages?.images}
          handleClose={handleClose}
          selectedImage={selectedImage}
        />
      ) : null}
    </>
  );
};
export default GalleryComponent;
