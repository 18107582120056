import { graphql, Link, navigate } from "gatsby";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { getResortUrl, getVillaUrl, filterActiveContent } from "../lib/helpers";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import Image from "gatsby-plugin-sanity-image";
import PortableText from "../components/Ui/portableText";
import VillaStyles from "../styles/VillaTemplateStyles";
import Amenities from "../components/Resort/Amenities";
import Activities from "../components/Resort/Activities";
import Accomodation from "../components/Resort/Accomodation";
import Spa from "../components/Resort/Spa";
import PopUpGallery from "../components/PopUpGallery";
import { VillaIcons } from "../components/Villa/VillaIcons";
import Highlights from "../components/Resort/Highlights";
import Restaurants from "../components/Villa/Restaurants";
import {
  Overlay,
  Banner,
  Button,
  PricingProvider,
  StickyNavBar,
  usePricingContext,
} from "../components";
import {
  ROOM_PAGE,
  ACCOMODATION,
  DEFAULT_NAVBAR_WITH_BOTTOM_LINK,
  SPA,
  HIGhLIGHTS,
  OVERVIEW,
  DINE,
  FEATURES,
  ISLAND,
  ACTIVITIES,
  VILLA_MODE,
  SHOW_PRICING_MODAL,
} from "../constants";
import { useIsMobile, useNavBar, usePageSectionsRef } from "../hooks";
import { RecommendedContentWrapper, EnquireButtonWrapper } from "./elements";

export const query = graphql`
  fragment SanityMonthPriceFields on SanityMonthlyPrice {
    normalPrice {
      price
      note
    }
    seasonalPrice {
      price
      startDate
      endDate
    }
  }
  query VillaTemplateQuery(
    $id: String!
    # $resortId: String!
    $rateModelId: String
  ) {
    villa: sanityVilla(_id: { eq: $id }) {
      _id
      priceOnRequest
      name
      alternateName
      tagline
      uniqueCode
      price
      numrooms
      headerImages {
        images {
          asset {
            _id
            gatsbyImage(
              width: 1024
              height: 500
              quality: 100
              fit: OUTSIDE
              placeholder: BLURRED
              formats: [WEBP]
            )
          }
          alt
        }
      }
      imageThumb {
        ...SanityImage
        alt
      }
      short_desc
      _rawDescription

      heroImage {
        ...SanityImage
        alt
      }

      roomFeatures {
        backgroundImage {
          ...SanityImage
          alt
        }
        features {
          _key
          _rawDescription
          title
        }
      }
      sizeSqm
      showers {
        option
        number
      }

      villaPoolTypes {
        poolType
      }

      maxOccupancy {
        option
        number
      }

      resort {
        id
        name
        shortName
        locationAtoll
        locationFull
        numberOfBars
        numberOfRestaurants
        numberOfRooms
        roomVoltage
        timeToAirport
        _rawDescription
        resortTransferType {
          transferType
        }

        villas {
          _id
          uniqueCode
          name
          active
          priceOnRequest
          imageThumb {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
            alt
          }
          resort {
            _id
            name
          }
          numrooms
          roomFeatures {
            features {
              _key
              _rawDescription
              title
            }
          }
          sizeSqm

          villaPoolTypes {
            poolType
          }

          maxOccupancy {
            option
            number
          }

          rateModel {
            monthlyPricing {
              january {
                ...SanityMonthPriceFields
              }
              february {
                ...SanityMonthPriceFields
              }
              march {
                ...SanityMonthPriceFields
              }
              april {
                ...SanityMonthPriceFields
              }
              may {
                ...SanityMonthPriceFields
              }
              june {
                ...SanityMonthPriceFields
              }
              july {
                ...SanityMonthPriceFields
              }
              august {
                ...SanityMonthPriceFields
              }
              september {
                ...SanityMonthPriceFields
              }
              october {
                ...SanityMonthPriceFields
              }
              november {
                ...SanityMonthPriceFields
              }
              december {
                ...SanityMonthPriceFields
              }
            }
          }
        }
        restaurants {
          name
          active
          alternateName
          description
          imageThumb {
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
            }
            alt
          }

          resort {
            name
          }
        }
        spa {
          id
          description
          name
          active
          imageWeb {
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
            }
            alt
          }
          imageThumb {
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
            }
            alt
          }
        }

        highlights {
          name
          active
          description
          imageThumb {
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
            }
            alt
          }
        }

        activities {
          name
          active
          imageThumb {
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
            }
            alt
          }
        }

        reviews {
          name
          description
        }
      }
      recommendedVillas {
        active
        content {
          name
          uniqueCode
          price
          priceOnRequest
          imageThumb {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }

            alt
          }
          resort {
            shortName
            name
          }
        }
      }
      dineBanner {
        active
        BannerTitle
        sectionHeroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          alt
        }
        sectionIconBadge {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
        descriptionShort
        bannerSize
      }
      islandBanner {
        active
        BannerTitle
        sectionHeroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          alt
        }
        sectionIconBadge {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
        descriptionShort
        bannerSize
      }
      activitiesBanner {
        active
        BannerTitle
        sectionHeroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          alt
        }
        sectionIconBadge {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
        descriptionShort
        bannerSize
      }
    }
    rateModel: sanityRateModel(_id: { eq: $rateModelId }) {
      generalNote
      monthlyPricing {
        january {
          ...SanityMonthPriceFields
        }
        february {
          ...SanityMonthPriceFields
        }
        march {
          ...SanityMonthPriceFields
        }
        april {
          ...SanityMonthPriceFields
        }
        may {
          ...SanityMonthPriceFields
        }
        june {
          ...SanityMonthPriceFields
        }
        july {
          ...SanityMonthPriceFields
        }
        august {
          ...SanityMonthPriceFields
        }
        september {
          ...SanityMonthPriceFields
        }
        october {
          ...SanityMonthPriceFields
        }
        november {
          ...SanityMonthPriceFields
        }
        december {
          ...SanityMonthPriceFields
        }
      }
    }
  }
`;

const pageSections = [
  { name: ACCOMODATION, hasSubNav: true, visibleOnMobile: true },
  { name: OVERVIEW, hasSubNav: false },
  { name: FEATURES, hasSubNav: false },
  { name: ISLAND, hasSubNav: false },
  { name: HIGhLIGHTS, hasSubNav: false },
  { name: DINE, hasSubNav: false },
  { name: SPA, hasSubNav: false },
  { name: ACTIVITIES, hasSubNav: false },
];

const VilaTemplate = (props) => {
  const [activeFeature, setActiveFeature] = useState(-1);
  const overviewRef = useRef();
  const { data } = props;
  const villa = data && data.villa;
  const resort = villa?.resort;
  const roomSlideIndex = props?.location?.state?.currentSlideIndex;
  const rateModel = data && data.rateModel;
  const spas = filterActiveContent(resort?.spa);
  const villaResortVillas = resort?.villas?.length ? resort?.villas : [];
  const restaurants = filterActiveContent(resort?.restaurants);
  const activities = filterActiveContent(villa?.resort?.activities);
  const highlights = filterActiveContent(villa?.resort?.highlights);
  const resortName = filterActiveContent(villa?.resort?.name);

  const resortShortName = villa?.resort?.shortName;
  const collectionPage = props?.location?.state?.collectionPage;
  const activitiesBanner = filterActiveContent(villa?.activitiesBanner);
  const islandBanner = filterActiveContent(villa?.islandBanner);
  const dineBanner = filterActiveContent(villa?.dineBanner);
  const { setPageName, setNavLinks, setHeroRef, resetValues } = useNavBar();
  // const heroRef = useRef();

  const recommendedVillas = villa?.recommendedVillas?.length
    ? filterActiveContent(villa?.recommendedVillas)
        ?.map(({ content }) => content)
        .flat()
    : [];

  // preparing this data to pass into navbar content for the accomodation section
  const resortVillas = useMemo(() => {
    const resortVillas_ = villaResortVillas?.length
      ? villaResortVillas?.filter(({ name }) => name !== villa.name)
      : [];
    return (
      resortVillas_.length &&
      resortVillas_?.map(({ name, _id, uniqueCode, imageThumb }, index) => {
        const onClick = () => {
          const villaUrl = getVillaUrl({
            name,
            resortName: villa?.resort?.shortName,
          });
          navigate(villaUrl);
        };
        return {
          className: index === 0 ? "page-title" : "",
          content: { name, uniqueCode },
          thumbImage: imageThumb,
          onClick: onClick,
        };
      })
    );
  }, [villa.resort.name]);

  const pageSections_ = useMemo(() => {
    return [
      {
        name: resortShortName,
        visibleOnMobile: true,
        onClick: () => {
          const url = getResortUrl({ shortName: resortShortName });
          navigate(url);
        },
      },
      ...pageSections,
    ].map((section) => {
      if (section.name === ACCOMODATION) {
        return {
          ...section,
          content: resortVillas,
        };
      }
      return section;
    });
  }, []);

  const {
    featuresRef,
    islandRef,
    highlightsRef,
    spaRef,
    activitiesRef,
    dineRef,
    navLinks,
  } = usePageSectionsRef(pageSections_);

  useEffect(() => {
    setPageName(DEFAULT_NAVBAR_WITH_BOTTOM_LINK);
    setNavLinks(navLinks);
    setHeroRef(overviewRef);
  }, [navLinks?.length]);

  useEffect(() => {
    return () => {
      resetValues();
    };
  }, []);

  const {
    name,
    _rawDescription: _rawDescriptionVilla,
    short_desc,
    roomFeatures,
    heroImage,
  } = villa;

  const {
    locationAtoll,
    numberOfBars,
    numberOfRestaurants,
    numberOfRooms,
    resortTransferType,
    timeToAirport,
    _rawDescription,
  } = villa.resort;

  const handleActiveFeature = (index) => {
    if (activeFeature !== index) {
      setActiveFeature(index);
    } else {
      setActiveFeature(-1);
    }
  };

  return (
    <Layout>
      {villa && (
        <SEO
          title={name || "Untitled"}
          description={short_desc}
          image={heroImage}
        />
      )}

      <Container>
        <VillaStyles>
          <div className="breadcrumb-wrapper">
            <Overlay bgColor="white" opacity={1} />
            <div className="breadcrumb">
              <ul>
                <li>
                  <Link className="backtoresort" to={`/`}>
                    Home
                  </Link>
                </li>
                <li>&gt;</li>
                <li>
                  <Link
                    className="backtoresort"
                    to={
                      collectionPage
                        ? collectionPage?.url
                        : `/${villa?.resort?.shortName
                            ?.toLowerCase()
                            .split(" ")
                            .join("-")}`
                    }
                    state={
                      collectionPage
                        ? {}
                        : {
                            redirectedFrom: ROOM_PAGE,
                            currentSlideIndex: roomSlideIndex,
                          }
                    }
                  >
                    {collectionPage?.collectionName || resortName}
                  </Link>
                </li>
                <li>&gt;</li>
                <li className="backtoresort room-name">{name}</li>
              </ul>
            </div>
          </div>
          <PricingProvider>
            <VillaHeader
              villa={villa}
              rateModel={rateModel}
              elementRef={overviewRef}
              villaResortVillas={villaResortVillas}
            />
          </PricingProvider>
          <div
            className="villa__room-features"
            name={FEATURES}
            id="room-features"
          >
            <Overlay zIndex={1} opacity={0.7} />
            {roomFeatures?.backgroundImage &&
            roomFeatures?.backgroundImage.asset ? (
              <Image
                {...roomFeatures.backgroundImage}
                width={950}
                height={700}
                alt={roomFeatures.backgroundImage.alt}
              />
            ) : null}
            <div className="content" ref={featuresRef}>
              <ul className="accordion">
                <h2 className="roomfeaturetitle">Room features</h2>
                {roomFeatures?.features?.map(
                  ({ _key, title, _rawDescription }, index) => (
                    <li key={_key} className="accordion-item">
                      <input
                        id={index}
                        className="hide"
                        type="checkbox"
                        checked={activeFeature === index}
                      />
                      <label
                        htmlFor={index}
                        className="accordion-label"
                        onClick={() => handleActiveFeature(index)}
                      >
                        {title}
                      </label>

                      <ul className="accordion-child">
                        <PortableText blocks={_rawDescription} />
                      </ul>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div
            className="villa__property-overview"
            name={ISLAND}
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <Amenities
              innerRef={islandRef}
              locationAtoll={locationAtoll}
              numberOfBars={numberOfBars}
              numberOfRestaurants={numberOfRestaurants}
              numberOfRooms={numberOfRooms}
              resortTransferType={resortTransferType}
              timeToAirport={timeToAirport}
              _rawDescription={_rawDescription}
              title="Island Overview"
            />
          </div>
          <Banner banner={islandBanner} />
          <Highlights innerRef={highlightsRef} highlights={highlights} />
          <Restaurants innerRef={dineRef} restaurants={restaurants} />
          <Banner banner={dineBanner} />
          {spas?.length ? (
            <div className="villa__spa-overview" name={SPA}>
              {spas.map((spa) => (
                <Spa innerRef={spaRef} spa={spa} key={spa.name} />
              ))}
            </div>
          ) : null}

          {activities && (
            <Activities
              activities={activities}
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              innerRef={activitiesRef}
            />
          )}
          <Banner banner={activitiesBanner} />
          {recommendedVillas?.length ? (
            <RecommendedContentWrapper>
              <Accomodation
                id="recommendedVillas"
                title="You may also like"
                items={recommendedVillas}
                isRecommended
                carouselProps={{ slidesToShow: 3, centerPadding: "10px" }}
              />
            </RecommendedContentWrapper>
          ) : null}
        </VillaStyles>
      </Container>
    </Layout>
  );
};

export default VilaTemplate;

export const VillaHeader = ({ villa, elementRef, villaResortVillas }) => {
  const {
    _id: villaId,
    tagline,
    _rawDescription: _rawDescriptionVilla,
    headerImages,
  } = villa;
  const isMobile = useIsMobile();
  const { dispatch } = usePricingContext();

  const onEnquireClick = () => {
    dispatch({ type: SHOW_PRICING_MODAL });
  };

  return (
    <div id={OVERVIEW}>
      <div id="room-overview" className="villa__header" ref={elementRef}>
        <Overlay opacity={1} bgColor="white" />
        <div className="content" id="overview-content">
          <div className="container">
            {villa.uniqueCode && (
              <div className="unique_code_wrap">
                <strong>#</strong>
                {villa.uniqueCode}
              </div>
            )}

            <h2 className="villa_name_title">{villa.name}</h2>
            {isMobile ? (
              <PopUpGallery
                carouselClassName="gallery-carousel"
                headerImages={headerImages}
                styles={{ height: "100%" }}
                carouselProps={{ dots: false, adaptiveHeight: false }}
              />
            ) : null}
            {tagline && <p className="tagline">{tagline}</p>}
            <PortableText blocks={_rawDescriptionVilla} />
            <VillaIcons villa={villa} wrapperClassName="villa__header-icons" />
            {villa?.priceOnRequest ? (
              <>
                <div className="room-price">Price On Request</div>
                <EnquireButtonWrapper>
                  <Button className="boundless-btn" onClick={onEnquireClick}>
                    ENQUIRE
                  </Button>
                </EnquireButtonWrapper>
              </>
            ) : (
              <EnquireButtonWrapper>
                <Button className="boundless-btn" onClick={onEnquireClick}>
                  ENQUIRE
                </Button>
              </EnquireButtonWrapper>
            )}
          </div>
          {!isMobile ? (
            <PopUpGallery
              carouselClassName="gallery-carousel"
              headerImages={headerImages}
              styles={{ height: "100%" }}
              carouselProps={{ adaptiveHeight: false }}
            />
          ) : null}
        </div>
      </div>
      <StickyNavBar
        pricingMode={VILLA_MODE}
        defaultValues={{
          defaultRoomId: villaId,
          rooms: villaResortVillas,
        }}
      />
    </div>
  );
};
